.flex-container {
  display: flex;
  max-width: 900px;
  margin: 5px 5px;
  margin-bottom: 30px;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.flex-item {
  margin: 5px 0;
}
.textarea-1 {
  flex: 10 0 100%;
}
.textarea-2 {
  flex: 10 0 100%;
}
.button-one-third {
  flex: 1 0 32%;
}
.checkbox-container {
  flex: 1 0 100%;
  display: flex;
  margin: 15px auto;
}
.checkbox-distance {
  flex: 3 0 51%;
}
.basic-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.flex-100-space-between {
  flex: 1 0 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.time-duration {
  flex: 1 0 45%;
}
.color-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem; 
}
.color-selector .MuiSelect-select.MuiSelect-filled {
  padding: 0.5rem;
  padding-right: 1.5rem;
}
.color-selector svg {
  right: 0;
}
.color-option {
  width: 1rem;
  height: 1rem;
}

/* Auto prefixed CSS: */

/* * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 900px;
  margin: 10px 5px;
  margin-bottom: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.flex-item {
  margin: 5px 0;
}
.textarea-1 {
  -webkit-box-flex: 10;
      -ms-flex: 10 0 100%;
          flex: 10 0 100%;
}
.textarea-2 {
  -webkit-box-flex: 10;
      -ms-flex: 10 0 100%;
          flex: 10 0 100%;
  margin-bottom: 10px;
}
.button-transform {
  -webkit-box-flex: 5;
      -ms-flex: 5 0 50%;
          flex: 5 0 50%
}
.button-copy {
  -webkit-box-flex: 5;
      -ms-flex: 5 0 50%;
          flex: 5 0 50%
}
.checkbox-container {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 15px auto;
}
.checkbox-distance {
  -webkit-box-flex: 3;
      -ms-flex: 3 0 51%;
          flex: 3 0 51%;
}
.checkbox-hsy {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 35%;
          flex: 1 0 35%;
}

.send-button-container {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column wrap;
          flex-flow: column wrap;
}
.small-button-container {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;  
  margin-bottom: 10px
}
.button-email {
  -webkit-box-flex: 1;
      -ms-flex: 1 5;
          flex: 1 5;
}
.button-phone {
  -webkit-box-flex: 1;
      -ms-flex: 1 5;
          flex: 1 5;
}
.button-info {
  -webkit-box-flex: 4;
      -ms-flex: 4 1;
          flex: 4 1;
}
a {
  color: inherit;
  text-decoration: none;
  margin: -15px;
}
.sms-icon {
  margin-bottom: -7px
} */
