.hub {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 2vh;
  padding: 1rem;
  gap: 1rem;
}

.hub-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 20vw;
  max-height: 175px;
  background-color: var(--brand-color-very-light);
  border: 3px solid var(--brand-color-dark);
  color: var(--brand-color-very-dark) !important;
  font-size: 75% !important;
}

.hub a {
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 600px) {
  .hub-block {
    font-size: 1.75vw !important;
  }
}
