.logo {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 2px 10px 5px 10px;
  width: 100%;
  background-color: rgba(128, 128, 128, 1);
  margin: 0;
}
.text {
  padding-left: 2px;
  margin-top: 20px;
  font-size: 1.4rem;
  font-weight: 900;
  letter-spacing: 0.1px;
  font-family: 'Courier New', Courier, monospace;
  color: rgba(255, 255, 255, 0.836);
}

/* Auto prefixed CSS: */

/* .logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  padding: 2px 10px 5px 10px;
  width: 100%;
  background-color: rgba(128, 128, 128, 1);
  margin: 0;
}
.text {
  padding-left: 2px;
  margin-top: 20px;
  font-size: 1.4rem;
  font-weight: 900;
  letter-spacing: 2px;
  font-family: 'Courier New', Courier, monospace;
  color: rgba(255, 255, 255, 0.836)
} */
