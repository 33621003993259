body {
  overflow-x: hidden;
  margin: 0 auto;
  max-width: 900px;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
