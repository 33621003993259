.tab-panel {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}
.tab-panel-item {
  color: darkgray;
  padding: 5px;
  margin: 0;
  background-color: rgba(211, 211, 211, 0.171);
  padding: 0.25rem;
  min-width: 8rem;
  border-bottom: 3px solid darkgray;
  border-radius: 10px;
}
.tab-panel-item-selected {
  color: rgb(85, 85, 85);
  background-color: rgba(211, 211, 211, 0.692);
}

.p-0 {
  padding: 0 !important;
}

.filters-tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 2.5px;
  width: 100%;
}

.filters-tab-orders-count {
  margin-right: 0.5rem !important;
  color: darkgray;
  padding: 5px;
  margin: 0;
  background-color: rgba(211, 211, 211, 0.171);
  padding: 0.25rem;
  border-bottom: 3px solid darkgray;
  border-radius: 10px;
}

.filters-tab-orders-status-icon {
  margin-left: auto !important;
}

.order-pool-list {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}
/* .list-item-1 {
  width: 100%;
  margin: 10px 10px;
  line-height: 0.95rem;
  letter-spacing: 0.04rem;
} */

.basic-order-style {
  width: 100%;
  margin: 10px 10px;
  line-height: 0.95rem;
  letter-spacing: 0.04rem;
  margin-top: 10px;
  padding-bottom: 10px;
  color: rgb(63, 63, 63);
  text-overflow: ellipsis;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: normal;
  height: 200px;
}

.confirmed-order-style {
  display: inline-block;
  height: 80px;
  width: calc(100%);
  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
  color: darkgray;
}

.order-top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: rgba(211, 211, 211, 0.171);
  padding: 0.25rem;
  border-bottom: 3px solid darkgray;
  border-radius: 999999999px;
}

.order-actions {
  display: flex;
  justify-content: space-between;
  flex: 2 0 2;
}

.order-status-icons-container {
  display: flex;
  justify-content: flex-start;
  flex: 1 5 1;
}

.order-status-icon {
  padding: 3px 10px;
  border-radius: 9999px;
}

.order-status-icon-selected {
  background-color: #f8d96a !important;
}

.order-status-notification {
  background-color: var(--brand-color);
}

.order-status-notification-selected {
  background-color: var(--brand-color-dark) !important;
}

.order-status-confirmed {
  background-color: greenyellow;
}

.order-status-new-order {
  justify-self: end;
  color: white;
  font-weight: bold;
  background-color: lightskyblue;
}
